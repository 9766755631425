/* This stylesheet generated by Transfonter (https://transfonter.org) on November 4, 2017 2:22 AM */

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-Light.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-LightItalic.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-Regular.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-Italic.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-Medium.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-MediumItalic.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-Bold.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-BoldItalic.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-Black.woff) format('woff');
}

@font-face {
    font-family: 'PF Centro Sans Pro';
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    src: url(/fonts/centro/PFCentroSansPro-BlackItalic.woff) format('woff');
}